<template>
  <div class="container">
      <div class="col-md-12">
        <span class="d-flex justify-content-center">© 2024. All rights reserved</span>
      </div>
  </div>
</template>

<style scoped>

</style>